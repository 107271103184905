/* eslint react/jsx-no-comment-textnodes: "off" */
import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import Loadable from '@loadable/component'
import { getQuestions } from '../../actions/quiz.actions'
import { syncProducts } from '../../actions/product.actions'
import Loading from '../shared/loading'
import { partnerIFrames, SUBSCRIPTION_DELAY, DELAY_EXPIRED } from '../../constants/configs/landing'
import { checkUserAuth } from '../../actions/account.actions'
import AuthUser from '../auth/user'
import AuthUserPasswordless from '../auth/user/passwordless'
import AmpushAuthUser from '../partners/ampush/auth-user'
import queryString from 'query-string'
import { setCJEvent, setCoupon } from '../../actions/checkout.actions'
import { questionnairesSelector } from '../../utils/selectors'
import { pdpUrlsMain } from 'constants/endpoints'
import {
  getAllQuestionnaireInfo,
  getQuestionnaireInfo
} from '../../utils/questionnaire-configurator'
import { CONCERN_NAME_LIST } from '../../constants/questions'

const Question = Loadable(
  () => import(/* webpackChunkName: "questionpage" */ '../quiz/question-ab-experiment'),
  {
    fallback: <Loading />
  }
)

const Account = Loadable(() => import(/* webpackChunkName: "accountpage" */ '../account'))

const Maintenance = Loadable(() =>
  import(/* webpackChunkName: "accountpage" */ '../account/maintenance')
)

const Admin = Loadable(() => import(/* webpackChunkName: "adminpage" */ '../admin'))
const Checkout = Loadable(() => import(/* webpackChunkName: "checkoutpage" */ '../checkout'), {
  fallback: <Loading />
})

const HomePageB = Loadable(
  () => import(/* webpackChunkName: "homepage" */ '../pages/landing/home-old/index-v2'),
  {
    fallback: <Loading />
  }
)

const ScienceHomePage = Loadable(
  () => import(/* webpackChunkName: "homepage" */ '../pages/landing/science'),
  {
    fallback: <Loading />
  }
)

const MulberryHomePage = Loadable(
  () => import(/* webpackChunkName: "homepage" */ '../pages/landing/mulberry'),
  {
    fallback: <Loading />
  }
)

const TrialHomePage = Loadable(
  () => import(/* webpackChunkName: "homepage" */ '../pages/landing/trial'),
  {
    fallback: <Loading />
  }
)

const OfferHomePage = Loadable(
  () => import(/* webpackChunkName: "homepage" */ '../pages/landing/offer'),
  {
    fallback: <Loading />
  }
)

const ContactUs = Loadable(
  () => import(/* webpackChunkName: "contactuspage" */ '../pages/contact-us'),
  {
    fallback: <Loading />
  }
)

const WelcomeBackEmailPage = Loadable(
  () => import(/* webpackChunkName: "WelcomeBackEmailPage" */ '../pages/welcome-back-email'),
  {
    fallback: <Loading />
  }
)
const LandingLoginPage = Loadable(
  () => import(/* webpackChunkName: "landingloginpage" */ '../pages/landing-login'),
  {
    fallback: <Loading />
  }
)

const GiftRedeem = Loadable(
  () => import(/* webpackChunkName: "giftRedeemPage" */ '../pages/gift-certificates/redeem'),
  {
    fallback: <Loading />
  }
)

const GiftRedeemSuccessMessage = Loadable(
  () =>
    import(
      /* webpackChunkName: "giftRedeemPageSuccessMessage" */ '../pages/gift-certificates/success'
    ),
  {
    fallback: <Loading />
  }
)

const BuilderBlog = Loadable(
  () => import(/* webpackChunkName: "builderBlogpage" */ '../pages/blog'),
  {
    fallback: <Loading />
  }
)

const BuilderBlogPost = Loadable(
  () => import(/* webpackChunkName: "builderblogpostpage" */ '../pages/blog/builder-blog-post'),
  {
    fallback: <Loading />
  }
)

const LandingSubscriptionDelay = Loadable(
  () => import(/* webpackChunkName: "landing jc aug" */ '../pages/landing/subscription-delay'),
  {
    fallback: <Loading />
  }
)

const LandingExpired = Loadable(() => import('../pages/landing/subscription-delay/expired'), {
  fallback: <Loading />
})

const EmailUnsubscribe = Loadable(
  () => import(/* webpackChunkName: "email unsubscribe" */ '../pages/email-unsubscribe/'),
  {
    fallback: <Loading />
  }
)

const EmailUnsubscribeRegA = Loadable(
  () => import(/* webpackChunkName: "email unsubscribe" */ '../pages/email-unsubscribe-reg-a/'),
  {
    fallback: <Loading />
  }
)

const IFrame = Loadable(
  () => import(/* webpackChunkName: "landing jc aug" */ '../pages/landing/iframe'),
  {
    fallback: <Loading />
  }
)

const DynamicLanding = Loadable(
  () => import(/* webpackChunkName: "dynamicLanding" */ '../pages/dynamic-landing'),
  {
    fallback: <Loading />
  }
)

const Pdp = Loadable(() => import(/* webpackPrefetch: true */ '../pages/pdp'), {
  fallback: <Loading />
})

const BuilderPreview = Loadable(
  () => import(/* webpackChunkName: "builderPreview" */ '../pages/builder/preview'),
  {
    fallback: <Loading />
  }
)

const StartQuiz = Loadable(() => import(/* webpackChunkName: "quizStart" */ '../quiz/start'), {
  fallback: <Loading />
})

class Router extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // //skip loading questions & products on home page
    // //  home page will load after it's components have loaded
    // if (this.props.location && this.props.location.pathname
    //   && ["/", JC_AUG_V1, COME_BACK_5].includes(this.props.location.pathname)) {
    //   return;
    // }

    //Load Questions & products more intelligently
    //   Create new endpiont that will only return latest quiz version
    //   Only load Questions & products if the version has changed
    getAllQuestionnaireInfo().forEach(questionnaireInfo =>
      this.props.getQuestions(questionnaireInfo.id)
    )
    this.props.syncProducts()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }

    this.handlePromoAndAffiliate()
  }

  onRouteChanged = () => {
    window.scrollTo(0, 0)
  }

  handlePromoAndAffiliate = () => {
    const { location, setCoupon, setCJEvent } = this.props

    const parsedQueryStringParams = queryString.parse(location.search)

    const _getQueryStringParam = key => {
      const actualKey = Object.keys(parsedQueryStringParams).find(k => {
        return k.toLowerCase() === key.toLowerCase()
      })
      return actualKey ? parsedQueryStringParams[actualKey] : undefined
    }

    //This is for the Promo
    const promo = _getQueryStringParam('promo')
    if (promo) {
      setCoupon(promo)
    }

    //This is for CJ Affiliate
    //utm_content={eventId}&utm_source=CJ&utm_medium=affiliate
    const utm_source = _getQueryStringParam('utm_source')
    const utm_medium = _getQueryStringParam('utm_medium')
    const utm_content = _getQueryStringParam('utm_content')
    if (
      utm_source &&
      utm_medium &&
      utm_content &&
      utm_source.toUpperCase() === 'CJ' &&
      utm_medium.toLowerCase() === 'affiliate'
    ) {
      setCJEvent(utm_content)
    }
  }

  getQuestionsFiltered = (questions, questions_b) => {
    const questionsFiltered = questions
      ? questions.filter(q => {
          return !q.name
        })
      : []
    const questionsFiltered_b = questions_b
      ? questions_b.filter(q => {
          return !q.name
        })
      : []
    const allQuestionNames = Array.from(
      new Set(
        questionsFiltered
          .map(q => {
            return q.question.name
          })
          .concat(
            questionsFiltered_b.map(q => {
              return q.question.name
            })
          )
      )
    )
    return {
      allQuestionNames,
      questionsFiltered_b,
      questionsFiltered
    }
  }

  getQuestionsByQuestionnaireIdObject = () => {
    const { questionnaires } = this.props

    const questionsByQuestionnaireId = getAllQuestionnaireInfo().reduce(
      (questionsOb, questionnaireInfo) => {
        if (questionnaires && questionnaires[questionnaireInfo.id]) {
          questionsOb[questionnaireInfo.id] = {
            questions: questionnaires[questionnaireInfo.id].questions,
            questions_b: questionnaires[questionnaireInfo.id].questions_b
          }
        }
        return questionsOb
      },
      {}
    )
    return questionsByQuestionnaireId
  }

  /**
   * returns an array of current "Route"s for quizzes
   * TO NOT CHANGE return value TO A Reect.Fragment (nor "<></>") return value, SINCE '<Switch>' doesn't allow to have a fragment as child (it must be a '<Route>' )
   * @returns {*[<Route/>>]}
   */
  getQuestionnairesRoutes = () => {
    const questionsByQuestionnaireId = this.getQuestionsByQuestionnaireIdObject()

    const routes = []

    Object.keys(questionsByQuestionnaireId).forEach(questionnaireId => {
      const { allQuestionNames, questionsFiltered, questionsFiltered_b } =
        this.getQuestionsFiltered(
          questionsByQuestionnaireId[questionnaireId].questions,
          questionsByQuestionnaireId[questionnaireId].questions_b
        )
      const questionnaireInfoSkin = getQuestionnaireInfo(questionnaireId)

      routes.push(
        <Route
          key={`start${questionnaireId}`} //this is causing the three bottles to refresh
          exact
          path={questionnaireInfoSkin.questionnaireUriOpeningPage}
          render={() => (
            <StartQuiz
              questionnaireType={questionnaireInfoSkin.type}
              firstQuestionUri={questionnaireInfoSkin.questionnaireUriFirstQuestion}
            />
          )}
        />
      )

      routes.push(
        <Route
          key={`concern${questionnaireId}`} //this is causing the three bottles to refresh
          exact
          path={[
            questionnaireInfoSkin.questionnaireUri,
            questionnaireInfoSkin.questionnaireUriFirstQuestion
          ]}
          render={() => (
            <Question
              questionA={questionnaireInfoSkin.firstQuestionCached}
              questionB={questionnaireInfoSkin.firstQuestionCached}
              questionnaireId={questionnaireId}
            />
          )}
        />
      )

      if (questionnaireInfoSkin.questionnaireUri === '/quiz') {
        // For system quiz, add transition routes
        CONCERN_NAME_LIST.map(concernName => {
          routes.push(
            <Route
              key={questionnaireId + concernName} //this is causing the three bottles to refresh
              exact
              path={`${questionnaireInfoSkin.questionnaireUri}/${concernName}_transition`}
              render={() => (
                <Question
                  questionA={questionsFiltered.find(q => {
                    return q.question.name === `Q1_${concernName}`
                  })}
                  questionB={questionsFiltered_b.find(q => {
                    return q.question.name === `Q1_${concernName}`
                  })}
                  questionnaireId={questionnaireId}
                  showTransition
                />
              )}
            />
          )
        })
      }

      allQuestionNames.forEach(name => {
        routes.push(
          <Route
            key={questionnaireId + name} //this is causing the three bottles to refresh
            exact
            path={`${questionnaireInfoSkin.questionnaireUri}/${name}`}
            render={() => (
              <Question
                questionA={questionsFiltered.find(q => {
                  return q.question.name === name
                })}
                questionB={questionsFiltered_b.find(q => {
                  return q.question.name === name
                })}
                questionnaireId={questionnaireId}
              />
            )}
          />
        )
      })
    })
    return routes
  }

  render() {
    return (
      <Switch>
        {/* Delete once we're certain that we want Builder PDP route on the home page! */}
        {/* <Route exact path="/" component={HomePage} /> */}
        <Route exact path={pdpUrlsMain} component={Pdp} />
        {/* <Route exact path="/pages" component={HomePage} /> */}
        <Route exact path="/pages" component={Pdp} />
        <Route path="/auth/user/passwordless" component={AuthUserPasswordless} />
        <Route path="/auth/user" component={AuthUser} />
        <Route path="/amp/auth/user" component={AmpushAuthUser} />
        {/* this fixes a direct hit from marketing landin pages */}
        {this.getQuestionnairesRoutes()}

        <Route path="/account" component={Account} />
        <Route exact path="/checkout" component={Checkout} />
        <Route path="/admin" component={Admin} />
        {/*        <Route exact path="/about-us">
          <Redirect to={{ pathname: 'why-proven', state: { status: 301 } }}></Redirect>
        </Route>*/}
        <Route exact path="/contact-us" component={ContactUs} />
        {/* welcome-back-email */}
        <Route exact path="/welcome-back-email" component={WelcomeBackEmailPage} />
        <Route exact path="/login" component={LandingLoginPage} />
        <Route exact path="/login-email" component={LandingLoginPage} />
        <Route exact path="/login-email2" component={LandingLoginPage} />
        <Route exact path="/blog" component={BuilderBlog} />
        <Route path="/blog/:post" component={BuilderBlogPost} />
        <Route path="/__builder__/preview" component={BuilderPreview} />
        {/* Gift certificates */}
        <Route exact path="/gift-redeem/success/new" component={GiftRedeemSuccessMessage} />
        <Route exact path="/gift-redeem/success/existing" component={GiftRedeemSuccessMessage} />
        <Route path="/gift-redeem" component={GiftRedeem} />
        {/* Landing Pages */}
        <Route exact path="/science" component={ScienceHomePage} />
        <Route exact path="/mulberry" component={MulberryHomePage} />
        <Route exact path="/offer" component={OfferHomePage} />
        <Route exact path="/trial" component={TrialHomePage} />
        <Route exact path="/science/$promo=SCIENCE" component={ScienceHomePage} />
        <Route exact path={SUBSCRIPTION_DELAY} component={LandingSubscriptionDelay} />
        <Route exact path={DELAY_EXPIRED} component={LandingExpired} />
        <Route
          exact
          path={'/personalized-concerns'}
          render={() => (
            <HomePageB
              variant="A"
              location={this.props.location}
              pageEventName="personalized-concerns"
              trackEventName="landing_cta"
            />
          )}
        />
        <Route
          exact
          path={'/skimmers'}
          render={() => (
            <HomePageB
              variant="B"
              location={this.props.location}
              pageEventName="personalized-concerns"
              trackEventName="landing_cta"
            />
          )}
        />
        <Route
          exact
          path={'/digitalderm'}
          render={() => (
            <HomePageB
              variant="C"
              location={this.props.location}
              pageEventName="digitalderm"
              trackEventName="landing_cta"
            />
          )}
        />
        <Route
          exact
          path={'/all-about-you'}
          render={() => (
            <HomePageB
              variant="D"
              location={this.props.location}
              pageEventName="all-about-you"
              trackEventName="landing_cta"
            />
          )}
        />
        <Route exact path="/emailunsubscribesuccessful" component={EmailUnsubscribe} />
        <Route exact path="/regaemailunsubscribesuccessful" component={EmailUnsubscribeRegA} />
        <Route exact path="/maintenance" component={Maintenance} />
        {/* iFrame partner pages */}
        {partnerIFrames.map(partner => {
          return (
            <Route
              exact
              key={partner.key}
              path={partner.path}
              render={() => {
                return (
                  <IFrame
                    src={partner.src}
                    seoTitle={partner.seoTitle}
                    seoDescription={partner.seoDescription}
                  />
                )
              }}
            />
          )
        })}
        <Route
          exact
          path="/:segment1(skincare-ingredients)/:segment2/:segment3?"
          render={props => <DynamicLanding {...props} model="ingredient-page" />}
        />
        <Route
          exact
          path="/:segment1(skin-type)/:segment2/:segment3?"
          render={props => <DynamicLanding {...props} model="skin-type-page" />}
        />
        <Route
          exact
          path="/:segment1(skin-concern)/:segment2/:segment3?"
          render={props => <DynamicLanding {...props} model="skin-concern-page" />}
        />
        {/* Strapi landing pages */}
        <Route exact path="/:segment1/:segment2?/:segment3?" component={DynamicLanding} />
        {/* If no route matches it will display not found page */}
        <Redirect from="*" to="/not-found" />
      </Switch>
    )
  }
}

Router.propTypes = {
  getQuestions: propTypes.func,
  syncProducts: propTypes.func,
  location: propTypes.object,
  checkUserAuth: propTypes.func,
  setCoupon: propTypes.func.isRequired,
  setCJEvent: propTypes.func.isRequired
}

const mapStateToProps = state => {
  const questionnaires = questionnairesSelector()(state)
  return {
    questionnaires
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getQuestions,
    syncProducts,
    checkUserAuth,
    setCoupon,
    setCJEvent
  })(Router)
)
